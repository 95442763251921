import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import vuetify from './plugins/vuetify'
import './plugins'
import Croppa from 'vue-croppa'
import 'vue-croppa/dist/vue-croppa.css'
import * as GmapVue from 'gmap-vue'
import GmapCluster from 'gmap-vue/dist/components/cluster' // replace src with dist if you have Babel issues
import VueGoogleCharts from 'vue-google-charts'
import vueNumeralFilterInstaller from 'vue-numeral-filter';
import VueOffline from 'vue-offline'
import numeral from 'numeral'
import wb from "./registerSW";
import fullscreen from 'vue-fullscreen'
import mLongPress from './directives/m-long-press'
import VueInputAutowidth from 'vue-input-autowidth'
import i18n from './plugins/i18n'

Vue.use(VueInputAutowidth)

Vue.use(mLongPress, { duration: 3000 });

Vue.use(fullscreen);

Vue.prototype.$workbox = wb;

Vue.use(VueOffline);

// TODO: REFACTOR NUMERALJS USAGE
Vue.use(vueNumeralFilterInstaller, { locale: 'pt-br' });
numeral.register('locale', 'pt-br-m', {
  delimiters: {
    thousands: '.',
    decimal: ','
  },
  abbreviations: {
    thousand: 'mil',
    million: 'mi',
    billion: 'bi',
    trillion: 'tri'
  },
  ordinal: function (number) {
    return 'º';
  },
  currency: {
    symbol: 'R$'
  }
});
numeral.locale('pt-br-m');

Vue.use(GmapVue, {
  load: {
    key: 'AIzaSyDrv2zpws8ugNtCZw8YTIz4O7d0VGpnPYA',
    libraries: ['places', 'visualization'],
    region: 'BR',
    language: 'pt-BR'
  },
  installComponents: true
});
Vue.component('GmapCluster', GmapCluster)

Vue.use(VueGoogleCharts)

Vue.use(Croppa, { componentName: 'cropper' });

const moment = require('moment');
require('moment/locale/pt-br');
require('moment/locale/es');
Object.defineProperty(Vue.prototype, '$moment', { value: moment });

Vue.config.productionTip = false

new Vue({
  store,
  router,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')
