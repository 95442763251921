// Imports
import {
  make,
} from 'vuex-pathify'

const state = {
  data: {
    selected: null,
    items: {},
    tags: [],
    fleets: [],
    cities: [
      'Rio de Janeiro/RJ',
      'Belo Horizonte/MG',
      'Petrópolis/RJ',
      'Florianópolis/SC',
      'São José/SC',
      'Palhoça/SC',
      'Biguaçu/SC',
      'São Paulo/SP',
      'Guarulhos/SP',
    ]
  },

  views: {
    dashboard: {
      selectedList: 0,
    }
  },

  funnel: {
    toggle: ['ACT'] 
  },

  prototype: {
    id: null,
    partner_id: null,
    cpf: null,
    partner: null,
    fullname: null,
    photo: null,
    birthdate: null,
    email: null,
    phone: null,
    rg: {
      id: null,
      issuer: null
    },
    cnh: null,
    status: {
      value: null,
      super: null,
      changedAt: null,
    },
    signedupAt: null,
    viewedAt: null,
    buzzer: null,
    push: null,
    gps: null,
    os: null,
    app_version: null,
    docs: [],
    job: {
      service_model: null,
      apps: null,
      apps_percent: null,
      fulltime: null,
      region: null,
      rating: null,
      journey: null,
      experience: null,
    },
    address: {
      country: null,
      state: null,
      city: null,
      zip: null,
      neighborhood: null,
      street: null,
      number: null,
      compl: null
    },
    vehicle: {
      rental: null,
      parking: null,
      plate: null,
      chassis: null,
      brand: null,
      model: null,
      year: null,
      color: null,
      renavam: null,
      isOwner: false,
      ownerName: null,
      ownerRg: null,
      ownerOrg_exp: null,
      ownerCpf: null,
      ownerRelative: null,
    },
    payment: {
      bankCode: null,
      bank: null,
      branch: null,
      account: null,
      digit: null,
      type: null,
      compl: null,
    },
  },
  bankList: []
}

const mutations = make.mutations(state)

const actions = {}

const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
