import _ from 'lodash';
import dictionary from '@/dictionary'

const utils = {
  translate (data) {
    return _[_.isArray(data) ? 'map' : 'mapValues'](data, item => {
      return {
        ...item,
        text: this.$t(item.text)
      }
    })
  },
  selectText (element, copy=false, message='Texto copiado!') {
    window.getSelection().selectAllChildren(element);
    if (copy) {
      const text = element.innerText;
      navigator.clipboard.writeText(text).then(() => {
        this.toggleToast(true, message)
      });
    }
  },

  searchEngine (items, query, exact=false, fields=null, nil=false) {
    query = query==null ? '' : query.normalize("NFD").replace(/\p{Diacritic}/gu, "");
    const key = exact ? new RegExp(`^${query}$`, 'gi') : new RegExp(query.replace(/\b +\b/g,'|'), 'gi');
    fields = fields||_.keys(_.sample(items));
    return _[_.isArray(items) ? 'filter' : 'pickBy'](items, (item) => {
      return query != '' ? _.some(fields, f => {
        const field = f.indexOf('.')>=1 ? _.split(f, '.') : f;
        let value = _.get(item, _.isArray(field) ? field[0] : field);
        value = _.isArray(value) ? JSON.stringify(value) : value;
        // console.log('search', f, _.isArray(value) ? value.join(',') : _.isObject(value) ? _.values(value).join(',') : value);
        return _.isNil(value) ? false : key.test((_.isArray(value) ? value.join(',') : _.isObject(value) ? _.values(value).join(',') : value).normalize("NFD").replace(/\p{Diacritic}/gu, ""))
      }) : !nil;
    });
  },

  processDrivers (data, prototype={}) {
    let items = {};
    if (_.has(data, 'id_motorista')) {
      data = {
        [data.id_motorista]: data
      }
    }
    _.forEach(data, (item) => {
      items[item.id_motorista] = Object.assign(_.cloneDeep(prototype), {
        id: item.id_motorista,
        partner_id: item.id_parceiro,
        cpf: item.cpf_motorista,
        partner: item.parceiro,
        fullname: this.capitalize(item.nm_motorista),
        photo: item.foto_motorista,
        gender: item.genero,
        events: {
          created: this.$moment.utc(item.dt_criacao).local().format('DD/MM/YY').toString(),
          updated: this.$moment.utc(item.dt_alteracao).local().toISOString(),
        },
        birthdate: this.$moment(item.dt_nasc_motorista).format('DD/MM/YYYY').toString(),
        viewedAt: item.viewedAt,
        email: item.email_motorista,
        phone: item.wpp_motorista,
        cnh: item.cnh_motorista,
        rg: {
          id: item.rg_motorista,
          issuer: item.org_exp_motorista,
        },
        club_id: _.has(item, 'id_parceiro')&&item.id_parceiro!=null ? this.md5(item.id_parceiro.toString()).substr(0, 10) : null,
        gps: item.gps,
        push: item.notific,
        app_version: item.vs_app,
        os: item.vs_so,
        rating: item.destaque + 3,
        tags: item.tags!='' ? item.tags.split(',') : [],
        docs: _.has(item, 'documentos') ? _.keyBy(JSON.parse(item.documentos), 'criteria') : null,
        hive: item.hive,
        fleet: item.partner_id_fleet || item.id_fleet_id,
        job: {
          products: _.isNil(item.products_interest)||item.products_interest=='' ? null : item.products_interest.split(','),
          special_projects: item.special_projects_interest,
          apps: _.isNil(item.app_work)||item.app_work=='' ? null : item.app_work.split(','),
          apps_percent: item.app_work_percent,
          service_model: item.mod_trab,
          fulltime: item.nivel_env,
          region: item.regiao_rodada,
          rating: item.nota_app == null ? null : item.nota_app.toFixed(2),
          journey: item.nr_rodadas,
          experience: item.tempo_motorista,
        },
        status: {
          value: item.super_status_motorista=='UNS' ? 'SIG' : item.status_motorista,
          super: item.super_status_motorista,
          changedAt: item.dt_status_motorista,
        },
        buzzer: {
          id: item.id_device,
          code: item.cod_device,
          number: item.nr_patrimonio
        },
        reviews: _.orderBy(item.revisoes, ['data'], ['desc']),
        calendar: {
          start: _.has(item, 'agendamento') ? item.agendamento.start : null,
          end: _.has(item, 'agendamento') ? item.agendamento.end : null,
          status: _.has(item, 'agendamento') ? item.agendamento.status : null,
        },
        address: {
          country: item.pais,
          state: item.uf,
          city: item.cidade,
          zip: item.cep,
          neighborhood: item.bairro,
          street: item.logradouro,
          number: item.numero,
          compl: item.complemento
        },
        vehicle: {
          id: item.id_veiculo,
          parking: item.estacionamento,
          plate: item.placa,
          chassis: item.chassi,
          brand: item.marca,
          model: item.modelo,
          year: item.ano_fab,
          color: item.cor,
          fuel: _.isNil(item.fuel_type)||item.fuel_type=='' ? null : item.fuel_type.split(','),
          renavam: item.renavam,
          isOwner: item.dono,
          envelope_id: item.envelope_id,
          rental: item.nm_locadora,
          ownerName: item.nm_prop,
          ownerRg: item.rg_prop,
          ownerOrg_exp: item.org_exp_prop,
          ownerCpf: item.cpf_prop,
          ownerEmail: item.email_prop,
          ownerRelative: item.parent_prop,
        },
        payment: {
          bank: {
            id: item.id_banco,
            code: item.cod_banco,
            title: item.nm_banco,
          },
          branch: item.agencia,
          account: item.conta,
          digit: item.dv,
          type: item.tipo_conta,
          compl: item.comp_conta,
        },
      });
    });

    return items;
  },

  cacheDrivers (data, store, status=null) {
    let stale = !!status ? _.map(_.filter(_.cloneDeep(store), ['status.value', status]), 'id') : [];
    _.each(data, item => {
      if (stale.includes(item.id)) _.pull(stale, item.id);
      try {
        this.$set(store, item.id, item);
      } catch {
        console.warn('Storage space is full');
      }
    });
    _.each(stale, id => {
      this.$delete(store, id);
      // console.log('delete', store[id].nm_motorista);
    });
    return store;
  },
  
  getDictionary (term) {
    const d = _.clone(dictionary.default);
    const role = _.last(this.user.roles);
    const dict_role = _.find(dictionary, (terms, keys) => {
      const match = _.indexOf(_.split(keys, '|'), role.toString()) >= 0;
      return match;
    })
    const dict = _.isNil(dict_role) ? d : _.assign({}, d, dict_role);
    return _.isNil(term) ? dict : _.has(dict, term) ? dict[term] : null;
  },

  formatText (text) {
    const reg = {
      link: /\[([^\[]+)\]\(([^\)]+)\)(\^?)/gim, // [text](link) ^ can be added at end to set the target as 'blank'
      bold: /\*([^\*]+)\*/gim, // *bold*
      emphasis: /\*\*([^\*]+)\*\*/gim, // **emphasis**
      quote: /\{quote\}([^\*]+)\{quote\}/gim, // *quote*
      garbage: /\{adf\}([^\*]+)\{adf\}/gim, // *garbage*
      br: /\n/gim,
      data: /\{\{([^\*]+)\}\}/gim, // {{var}}
    }
    return _.isNil(text) ? text : text
      .replace(reg.data, (match, $1) => {
        return $1 in this ? this[$1] : '';
      })
      .replace(reg.link, (match, $1, $2, $3) => {
        var _target = $3 ? "blank" : ""; // check if '^' sign is present with link syntax
        return (
          "<a href='" +
          $2 +
          "'>" +
          $1 +
          "</a>"
        );
      })
      .replace(reg.bold, "<b>$1</b>")
      .replace(reg.emphasis, "<em>$1</em>")
      .replace(reg.quote, "<span class='text-quote'>$1</span>")
      .replace(reg.br, "<br/>")
      .replace(reg.garbage, "");
  },

  isAiring (active, start, end) {
    return this.$moment().isSameOrAfter(start, 'day') && this.$moment().isSameOrBefore(end, 'day') && active;
  },

  handleError (error, msg, toast) {
    if (!this.isValid(msg)) {
      console.log(this.isOnline);

      if (this.isOnline) {
        console.log('Network/Server Error', error);
        msg = this.$t('errors.unknown');
      }else{
        msg = this.$t('errors.offline');
        console.log('Offline');
      }
    }
    toast = this.isValid(toast) ? toast : false;
    if (toast) {
      this.toggleToast(
        true,
        msg,
        7000,
        false
      );
    }
    // if a `message` property exists or not
    const exception = error.message || error;
    this.$gtag.exception(exception);
  },

  trackEvent (category, action, label, value) {
    let event = {};
    if (this.isValid(category)) event['eventCategory'] = category;
    if (this.isValid(action)) event['eventAction'] = action;
    if (this.isValid(label)) event['eventLabel'] = label;
    if (this.isValid(value)) event['eventValue'] = value;

    this.$gtag.event(event);
  },

  getParam (route, key) {
    return key in route.params ? route.params[key] : null;
  },
  getQuery (route, key) {
    console.log(route);
    return key in route.query ? route.query[key] : null;
  },

  portrait () {
    const w = window.innerWidth;
    const h = window.innerHeight;
    return h > w;
  },

  breakpoint (min=null, max=null, log=false) {
    const sizes = {
      'xs': 600, 
      'sm': 960, 
      'md': 1264, 
      'lg': 1904,
      'xl': 4000
    };
    const w = window.innerWidth;
    // console.log(window.innerWidth > sizes[min], window.innerWidth < sizes[max]);
    let s, e, r;
    if (!!min||!!max) {
      if (!_.isNil(min)) {
        s = _.has(sizes, min) ? w > sizes[min] : min;
        if (!_.isNil(max)) {
          e = _.has(sizes, max) ? w < sizes[max] : max;
          r = s && e;
        }else{
          r = s;
        }
      }else{
        r = _.has(sizes, max) ? w < sizes[max] : max;
      }
    }else{
      r = _.findKey(sizes, size => size>=w);
    }
    if (log) console.log(s, e, r);
    return r;
  },

  slugify (text) {
    const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìıİłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;'
    const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------'
    const p = new RegExp(a.split('').join('|'), 'g')
  
    return text.toString().toLowerCase()
      .replace(/\s+/g, '-') // Replace spaces with -
      .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
      .replace(/&/g, '-and-') // Replace & with 'and'
      .replace(/[^\w\-]+/g, '') // Remove all non-word characters
      .replace(/\-\-+/g, '-') // Replace multiple - with single -
      .replace(/^-+/, '') // Trim - from start of text
      .replace(/-+$/, '') // Trim - from end of text
  },

  logout (cpf, token) {
    axios
      .get('/logout/'+cpf+'/'+token+'/?format=json')
      .then(response => {
        console.log('logout => ',response);
        if(response.data[0].retorno==200||response.data[0].retorno==401){
          this.getout('logout');
        }else{
          this.toggleToast(
            true,
            'Não foi possível realizar o logout.',
            5000,
            false,
          );
        }
      })
      .catch(error => {
        console.log('Network/Server Error', error);
        this.toggleToast(
          true,
          'Erro na solicitação.',
          7000,
          false,
          'error'
        );
      });
  },

  getout (caller) {
    console.log('getout', caller);
    // this.user.auth = Object.assign({}, this.user.auth, {
    //   token: null,
    //   timestamp: null,
    //   status: false,
    // });
    // var temp = {
    //   user: null,
    //   redirect: this.redirect
    // }
    // this.setStorage("temp", JSON.stringify(temp));
    this.delStorage('user').then(() => {
      // localStorage.removeItem("vuex");
      
      console.log('logged out', this.user.auth.status);
      window.location.href = '/login';
    });
  },

  toggleToast (toggle, text, timeout=7000, button, color) {
    if (!toggle) this.toast = Object.assign({}, this.toast, {
      toggle: toggle,
      text: '',
      timeout: -1,
      button: {
        toggle: false,
        texto: 'Ok',
        action: () => {}
      },
      color: ''
    });
    if (typeof button == 'undefined'||typeof button == 'boolean') {
      button = {
        toggle: button===true,
        text: 'Ok',
        action: false
      };
    }
    if(typeof color == 'undefined'){
      color = '';
    }
    this.toast = Object.assign({}, this.toast, {
      toggle,
      text,
      timeout,
      button,
      color
    });
  },

  campaignPeriod (campaigns, id, groups, full) {
    campaigns = _.isNil(id) ? null : _.isNil(groups) || _.isEmpty(groups) ? [campaigns[id]] : [campaigns[id], ..._.map(groups, id => campaigns[id])];
    const period = !_.isNil(campaigns) ? _.reduce(campaigns, (period, campaign) => {
      return {
        start: this.$moment(period.start).isBefore(campaign.period.start) ? period.start : campaign.period.start,
        end: this.$moment(period.end).isAfter(campaign.period.end) ? period.end : campaign.period.end,
      }
    }, { start: this.$moment().format('YYYY-MM-DD'), end: this.$moment(0).format('YYYY-MM-DD') }) : {
      start: this.$moment().subtract(7,'d').format('YYYY-MM-DD'),
      end: this.$moment().format('YYYY-MM-DD'),
    };
    if ((_.isNil(full)||!full)&&this.$moment().isBefore(period.end)) period.end = this.$moment().format('YYYY-MM-DD');
    console.log('segment period:', period.start, period.end);
    return [period.start, period.end];
  },

  normalize (data) {
    return this.isValid(data) && typeof data == 'string' ? data.toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : '';
  },

  capitalize (s) {
    if (typeof s !== 'string') return ''
    s = s.toLowerCase();
    return _.map(s.split(' '), p => p.length > 2 ? p.charAt(0).toUpperCase() + p.slice(1) : p).join(' ');
  },

  rawData (data) {
    return this.isValid(data) ? data.replace(/[^\w]/gi, '') : '';
  },

  formatCPF (cpf) {
    cpf = this.rawData(cpf);
    return cpf.substr(0,3)+'.'+cpf.substr(3,3)+'.'+cpf.substr(6,3)+'-'+cpf.substr(9,2);
  },

  formatBuzzerCode (code) {
    code = this.rawData(code);
    return code.substr(0,4)+' '+code.substr(4,4)+' '+code.substr(8,4)+' '+code.substr(12,4);
  },

  isValid (data) {
    return typeof data !== 'undefined' ? data !== null : false;
  },

  cancelToasts () {
    if (this.toast.toggle) {
      this.toast = Object.assign({}, this.toast, {
        toggle: false
      });
    }
  },

  i18nLanguageTest (supported, languages) {
  
    const compareLanguages = (ref, obj, path='') => {
      for (const key in ref) {
        if (ref.hasOwnProperty(key)) {
          const newPath = path ? `${path}.${key}` : key;
  
          if (!obj.hasOwnProperty(key)) {
            console.warn(`${newPath} is missing the translation`);
          } else if (typeof ref[key] !== typeof obj[key]) {
            console.warn(`${newPath} has different types`);
          } else if (typeof ref[key] === 'object' && typeof obj[key] === 'object') {
            compareLanguages(ref[key], obj[key], newPath);
          }
        }
      }
  
      for (const key in obj) {
        if (obj.hasOwnProperty(key) && !ref.hasOwnProperty(key)) {
          const newPath = path ? `${path}.${key}` : key;
          console.warn(`${newPath} is missing in ref`);
        }
      }
    }
  
    const ref = supported[0];
    let translations = JSON.parse(JSON.stringify(languages));
    delete translations[ref];
    for (const k in translations) {
      compareLanguages(languages[ref], translations[k]);
    };
  },

  getOptimalLocale(availableLocales, defaultLocale = 'en') {
    if (!Array.isArray(availableLocales) || availableLocales.length === 0) {
        throw new Error("Available locales must be a non-empty array");
    }

    // Get the user's preferred languages from the browser
    let userLocales = [Intl.DateTimeFormat().resolvedOptions().locale].concat(navigator.languages || [navigator.language || navigator.userLanguage]);
    
    // Normalize locales to lowercase for comparison
    const normalizedAvailable = availableLocales.map(loc => loc.toLowerCase());

    // Exact match
    // for (const locale of userLocales) {
    //     const lowerLocale = locale.toLowerCase();
    //     if (normalizedAvailable.includes(lowerLocale)) {
    //         return lowerLocale;
    //     }
    // }

    // Partial match (match language without region, e.g., "en-US" → "en")
    for (const locale of userLocales) {
        const langOnly = locale.split('-')[0].toLowerCase();
        const match = normalizedAvailable.find(avail => avail.startsWith(langOnly));
        if (match) {
            return match;
        }
    }

    // Return the default locale if no match is found
    return defaultLocale.toLowerCase();
  },

  interpolateHsl (lowHsl, highHsl, fraction) {
    var color = [];
    for (var i = 0; i < 3; i++) {
      // Calculate color based on the fraction.
      color[i] = (highHsl[i] - lowHsl[i]) * fraction + lowHsl[i];
    }

    return 'hsl(' + color[0] + ',' + color[1] + '%,' + color[2] + '%)';
  },

  random (min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  },

  setStorage (key, value, type='localStorage') {
    return Promise.resolve().then(() => {
      if (_.isObject(value)) value = JSON.stringify(value);
      window[type].setItem(key, value);
    });
  },
  getStorage (key, type='localStorage') {
    return Promise.resolve().then(async () => {
      let value = await window[type].getItem(key);
      if (_.startsWith(value, '{')||_.startsWith(value, '[')||value=='true'||value=='false') value = JSON.parse(value);
      return value;
    });
  },
  delStorage (key, type='localStorage') {
    return Promise.resolve().then(function () {
      window[type].removeItem(key);
    });
  },
}

const icons = {
  cpm: "M8.284 11.8622C6.376 11.3911 4 10.8044 4 8.33333C4 6.74222 5.323 5.39111 7.15 4.93778V3H9.85V4.93778C11.677 5.39111 13 6.74222 13 8.33333H11.2C11.2 7.37333 9.967 6.55556 8.5 6.55556C7.033 6.55556 5.8 7.37333 5.8 8.33333C5.8 9.31111 6.736 9.66667 8.716 10.1378C9.73641 10.3897 10.8907 10.6747 11.7438 11.2634L10.4496 12.5552C9.96809 12.2946 9.25039 12.0922 8.284 11.8622ZM4 13.6667H5.8C5.8 14.4325 6.5846 15.1078 7.6467 15.3496L6.25382 16.7509C4.90598 16.1357 4 14.9833 4 13.6667ZM15.6667 15L13.3333 18L11.6667 16L9.33333 19H18.6667L15.6667 15ZM20 10.3333V19.6667C20 20.0203 19.8595 20.3594 19.6095 20.6095C19.3594 20.8595 19.0203 21 18.6667 21H9.33333C8.97971 21 8.64057 20.8595 8.39052 20.6095C8.14048 20.3594 8 20.0203 8 19.6667V17L16 9H18.6667C19.4 9 20 9.59333 20 10.3333Z"
};

export { 
  utils as default,
  icons
}
